<template>
  <base-layout show-back-btn="true" page-default-back-link="/" show-info="true">
    <ion-text color="primary" class="ion-text-center title-headline">
      <h1>Kalender</h1>
      <ion-text color="secondary">
        <h3>{{ dayName }} {{ dateString }}</h3>
      </ion-text>
    </ion-text>
    <ion-item>
      <ion-icon slot="start" :icon="icons.calendar"></ion-icon>
      <ion-label>Dato</ion-label>
      <ion-datetime
        display-format="DD/MM/YYYY"
        picker-format="DD/MMMM/YYYY"
        display-timezone="utc"
        v-model="theDate"
        max="2099-10-31"
        placeholder="Vælg dato"
      ></ion-datetime>
    </ion-item>
    <ion-item class="filter-button" @click="showFilters = !showFilters">
      <ion-icon :icon="icons.filter" slot="start"></ion-icon>
      <ion-input readonly>
        Filtre
      </ion-input>
      <ion-icon :icon="chevron" class="chevron-icon"></ion-icon>
    </ion-item>
    <div class="filter-dropdown" v-if="showFilters && filters">
      <template v-for="(filter, key, index) in filters" :key="index">
        <template v-if="filter.name != 'Dato'">
          <ion-item>
            <ion-label :for="filter.name">
              <ion-text>{{ filter.name }}</ion-text>
            </ion-label>
            <ion-checkbox
              :name="filter.name"
              color="primary"
              v-model="filter.value"
            ></ion-checkbox>
          </ion-item>
        </template>
      </template>

      <ion-button fill="clear" @click="resetFilters" class="asLink">
        <ion-icon
          :icon="icons.close"
          slot="start"
          class="reset-icon"
        ></ion-icon>
        Nulstil
      </ion-button>
    </div>
    <ion-list v-if="dateValid" class="work-list">
      <template
        v-for="(assignedPerson, region) in sortedWorkPlanRegions"
        :key="region"
      >
        <ion-item
          button="true"
          :color="getItemColor(region)"
          @click="setActive(region)"
        >
          <ion-label>{{ region }}</ion-label>
          <ion-icon
            :icon="region === isActive ? icons.up : icons.down"
          ></ion-icon>
        </ion-item>
        <work-plan-person
          v-if="region === isActive"
          :person="getPerson(assignedPerson)"
        ></work-plan-person>
      </template>
    </ion-list>
    <h3 v-else style="text-align: center; margin-top: 20px;">
      Ingen kalender for valgte dato
    </h3>
  </base-layout>
</template>

<script>
import { mapGetters } from "vuex";
import {
  callSharp,
  chevronDown,
  chevronUp,
  calendarOutline,
  filter,
} from "ionicons/icons";
import WorkPlanPerson from "../components/app/WorkPlanPerson";
import moment from "moment";

import {
  IonButton,
  IonItem,
  IonLabel,
  IonIcon,
  IonText,
  IonList,
  IonDatetime,
  IonCheckbox,
  IonInput,
} from "@ionic/vue";

export default {
  name: "Calendar",
  data() {
    return {
      showFilters: false,
      theDate: null,
      dayName: null,
      dateString: null,
      workPlanDay: null,
      days: [
        "Søndag",
        "Mandag",
        "Tirsdag",
        "Onsdag",
        "Torsdag",
        "Fredag",
        "Lørdag",
      ],
      isActive: null,
      icons: {
        phone: callSharp,
        down: chevronDown,
        up: chevronUp,
        calendar: calendarOutline,
        filter: filter,
      },
      filters: null,
      dateValid: false,
    };
  },
  components: {
    IonButton,
    IonItem,
    IonLabel,
    IonIcon,
    IonText,
    IonList,
    IonDatetime,
    WorkPlanPerson,
    IonCheckbox,
    IonInput,
  },
  computed: {
    ...mapGetters(["workPlanData", "data"]),
    chevron() {
      return this.showFilters ? this.icons.up : this.icons.down;
    },
    workPlanRegions() {
      if (!this.workPlanDay) return {};

      // Convert workPlanDay entries to object, excluding 'Dato'
      return Object.fromEntries(
        this.workPlanDay
          .filter(([key]) => key !== "Dato")
          .map(([region, assignedPerson]) => [region, assignedPerson])
      );
    },
    filteredList() {
      if (!this.workPlanDay) return {};

      const activeFilters =
        this.filters?.filter((filter) => filter.value) || [];

      // If no filters are active, return all regions except Dato
      if (activeFilters.length === 0) {
        return this.workPlanRegions;
      }

      // Return filtered regions
      return Object.fromEntries(
        Object.entries(this.workPlanRegions).filter(([region]) =>
          activeFilters.some((filter) => filter.name === region)
        )
      );
    },
    sortedWorkPlanRegions() {
      // Put Serviceleder first, then sort other regions alphabetically
      const entries = Object.entries(this.filteredList);
      const serviceleder = entries.find(
        ([region]) => region === "Serviceleder"
      );
      const otherRegions = entries
        .filter(([region]) => region !== "Serviceleder")
        .sort(([regionA], [regionB]) => regionA.localeCompare(regionB));

      return Object.fromEntries(
        serviceleder ? [serviceleder, ...otherRegions] : otherRegions
      );
    },
  },
  watch: {
    theDate: {
      handler: function(val) {
        if (val) {
          this.dayName = this.days[moment(val).day()];
          this.dateString = moment(val).format("DD/MM/YYYY");
          var data = this.workPlanData.find((x) => x.Dato == this.dateString);
          if (data != null || data != undefined) {
            this.dateValid = true;
            this.workPlanDay = Object.entries(data);
            this.setFilters();
          } else {
            this.dateValid = false;
          }
        }
      },
      immediate: false,
    },
  },
  mounted() {
    this.getDate();
  },
  methods: {
    resetFilters() {
      if (this.filters) {
        this.filters.forEach((filter) => {
          filter.value = false;
        });
      }
    },
    setActive(region) {
      this.isActive = this.isActive === region ? null : region;
    },
    toggleFilter(key) {
      this.filters[key] = !this.filters[key];
    },
    setFilters() {
      if (!this.workPlanDay) {
        this.filters = [];
        return;
      }

      // Create filter objects for all regions except Dato
      this.filters = Object.keys(this.workPlanRegions).map((region) => ({
        name: region,
        value: false,
      }));
    },
    getItemColor(region) {
      return region === "Serviceleder" ? "serviceleder" : "secondary";
    },
    getDate() {
      var datePlus = moment().format();
      this.theDate = datePlus;
    },
    getPerson(ini) {
      if (ini) {
        var person = this.data.find(
          (x) => x.Initialer.toUpperCase() == ini.toUpperCase()
        );
        return person ? person : null;
      }
      return null;
    },
  },
};
</script>
